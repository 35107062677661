import * as React from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { jsx, Box, Input, Label, Button } from 'theme-ui'

export default class MailchimpComponent extends React.Component {
  state = {
    email: "",
  }
  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

  // 1. via `.then`
  _handleSubmit = (e) => {
    e.preventDefault();
    // @ts-ignore
    addToMailchimp(this.state.email, this.state, null) // listFields are optional if you are only capturing the email address.
    .then(({ msg, result }) => {
      console.log('msg', `${result}: ${msg}`)

      if (result !== 'success') {
          throw msg
      }
      alert(msg)
  })
    .catch(() => {
      // unnecessary because Mailchimp only ever
      // returns a 200 status code
      // see below for how to handle errors
    })
  }

  _handleChange = e => {
    console.log({
        [`${e.target.name}`]: e.target.value,
    })
    this.setState({
        [`${e.target.name}`]: e.target.value,
    })
}

  render () {
    return (
      <Box as="form" onSubmit={this._handleSubmit}>
        <Label htmlFor="email">Email</Label>
        <Input type="email" onChange={this._handleChange} name="email" id="email" />
        <Button type="submit">Send</Button>
      </Box>
    )
  }
}
